import { CommonHero } from '@/CommonHero';
import { Fragment } from 'react';

const ContactUs: Page = () => (
    <Fragment>
        <CommonHero>
            <h1>Contact Us</h1>
            <p>We are available on our office hours for you.</p>
        </CommonHero>
    </Fragment>
);

export default ContactUs;
